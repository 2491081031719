var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"480px","persistent":""},model:{value:(_vm.state.visible),callback:function ($$v) {_vm.$set(_vm.state, "visible", $$v)},expression:"state.visible"}},[_c('v-card',[_c('validation-observer',{ref:"validatorRef",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('v-card-title',{staticClass:"green lighten-5"},[_c('span',[_vm._v("Cont nou")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.state.visible = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-subtitle',{staticClass:"green lighten-5"},[_vm._v("Ai deja cont? Click "),_c('router-link',{attrs:{"to":{ name: 'login' }}},[_vm._v("aici")]),_vm._v(" pentru a te autentifica.")],1),_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.fields.lastName.label,"rules":_vm.fields.lastName.rules,"vid":"lastName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.fields.lastName.label,"error-messages":errors,"filled":""},model:{value:(_vm.fields.lastName.value),callback:function ($$v) {_vm.$set(_vm.fields.lastName, "value", $$v)},expression:"fields.lastName.value"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.fields.firstName.label,"rules":_vm.fields.firstName.rules,"vid":"firstName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.fields.firstName.label,"error-messages":errors,"filled":""},model:{value:(_vm.fields.firstName.value),callback:function ($$v) {_vm.$set(_vm.fields.firstName, "value", $$v)},expression:"fields.firstName.value"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.fields.email.label,"rules":_vm.fields.email.rules,"vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.fields.email.label,"error-messages":errors,"filled":""},model:{value:(_vm.fields.email.value),callback:function ($$v) {_vm.$set(_vm.fields.email, "value", $$v)},expression:"fields.email.value"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.fields.phone.label,"rules":_vm.fields.phone.rules,"vid":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.fields.phone.label,"error-messages":errors,"filled":""},model:{value:(_vm.fields.phone.value),callback:function ($$v) {_vm.$set(_vm.fields.phone, "value", $$v)},expression:"fields.phone.value"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.fields.address.label,"rules":_vm.fields.address.rules,"vid":"address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.fields.address.label,"error-messages":errors,"filled":""},model:{value:(_vm.fields.address.value),callback:function ($$v) {_vm.$set(_vm.fields.address, "value", $$v)},expression:"fields.address.value"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.fields.password.label,"rules":_vm.fields.password.rules,"vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.fields.password.label,"error-messages":errors,"type":"password","filled":""},model:{value:(_vm.fields.password.value),callback:function ($$v) {_vm.$set(_vm.fields.password, "value", $$v)},expression:"fields.password.value"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.fields.passwordConfirmation.label,"rules":_vm.fields.passwordConfirmation.rules,"vid":"passwordConfirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.fields.passwordConfirmation.label,"error-messages":errors,"type":"password","filled":""},model:{value:(_vm.fields.passwordConfirmation.value),callback:function ($$v) {_vm.$set(_vm.fields.passwordConfirmation, "value", $$v)},expression:"fields.passwordConfirmation.value"}})]}}],null,true)})],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"plain":""},on:{"click":function($event){_vm.state.visible = false}}},[_vm._v("Inchide")]),_c('v-btn',{attrs:{"type":"submit","color":"primary"}},[_vm._v("Trimite")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }