import router from '@/router';
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api';
import { dispatchAxiosErrorAlert, extractFieldsData, injectStrict } from '@/lib/misc';
import { AxiosKey, StoreKey } from '@/symbols';
import { useToast } from 'vue-toastification/composition';
export default defineComponent({
    setup() {
        const store = injectStrict(StoreKey);
        const axios = injectStrict(AxiosKey);
        const validatorRef = ref(null);
        const toast = useToast();
        const state = reactive({
            visible: true,
            formData: computed(() => extractFieldsData(fields))
        });
        const fields = reactive({
            lastName: {
                label: 'Nume',
                value: '',
                rules: {
                    required: true
                }
            },
            firstName: {
                label: 'Prenume',
                value: '',
                rules: {
                    required: true
                }
            },
            email: {
                label: 'Adresa e-mail',
                value: '',
                rules: {
                    required: true,
                    email: true
                }
            },
            phone: {
                label: 'Telefon',
                value: '',
                rules: {
                    required: false
                }
            },
            address: {
                label: 'Adresa',
                value: '',
                rules: {
                    required: true
                }
            },
            password: {
                label: 'Parola',
                value: '',
                rules: {
                    required: true
                }
            },
            passwordConfirmation: {
                label: 'Repeta parola',
                value: '',
                rules: {
                    required: true,
                    confirmed: 'password'
                }
            }
        });
        const onSubmit = () => validatorRef.value && validatorRef.value.validate()
            .then(() => axios.post('register', state.formData)
            .then(() => {
            toast.success('Contul a fost creat cu succes. Veti primi un e-mail cu detaliile de activare ale contului.');
            router.push({ name: 'home' });
        })
            .catch((err) => {
            if (validatorRef.value) {
                if (err.response?.status === 422) {
                    validatorRef.value.setErrors(err.response?.data?.data || []);
                }
                else {
                    dispatchAxiosErrorAlert(store, err);
                }
            }
        }));
        watch(() => state.visible, v => {
            if (!v) {
                setTimeout(() => { router.push({ name: 'home' }); }, 300);
            }
        });
        return {
            state,
            fields,
            validatorRef,
            onSubmit
        };
    }
});
